<template>
	<div>
		<header>
			<nav class="navbar navbar-expand-lg px-5">
				<a class="navbar-brand mx-auto" href="#">
					<img :src="logo" alt="bosCOD | Platform Kurir COD/Non-COD" width="auto" height="50">
				</a>
				<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span class="navbar-toggler-icon"></span>
				</button>

				<div class="collapse navbar-collapse" id="navbarSupportedContent">
					<ul class="navbar-nav mx-auto">
						<li class="nav-item">
							<a class="nav-link" href="https://boscod.com/#layanan">Layanan</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" href="https://boscod.com/#alur">Alur Pengiriman</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" :href="url + '/ongkir'">Cek Ongkir</a>
						</li>
						<li class="nav-item">
							<a class="nav-link" :href="url + '/lacak'">Lacak</a>
						</li>
					</ul>
					<div class="form-inline d-flex justify-content-around my-2 my-lg-0">
						<a class="btn btn-outline-primary rounded my-2 my-sm-0 mx-2 text-primary" href="login">Masuk</a>
						<a class="btn btn-primary rounded my-2 my-sm-0 mx-2 text-light" href="order/create/single">Mulai Kirim</a>
					</div>
				</div>
			</nav>
		</header>
		<main role="main">
			<section class="container">
				<div class="row mb-5">
					<div class="col-10 col-sm-6 col-lg-4 m-auto text-center">
						<img class="w-100" :src="image">
						<button class="btn btn-lg btn-primary mt-3" @click="$router.back()"><span class="h5">Kembali ke Halaman Sebelumnya</span></button>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>
<script>
import Vue from 'vue'
export default {
	name: "404 Not Found",
	components: {
	},
	data() {
		return {
			url: window.location.origin,
			logo: '',
			image: '',
		};
	},
	created: function(){
		if(Vue.config.appHost == 'xpress')
			this.logo = require(`../assets/img/logo-xpress.png`);
		else this.logo = require(`../assets/img/logo-min.svg`);

		this.image = require('../assets/img/404.svg')
	},
	methods: {
	},
};
</script>
<style scoped src="../assets/login.css"></style>
<style scoped>
</style>
